import {notificate} from 'common/helpers'
import {NOTIFICATIONS_TYPE} from 'consts.json'
import {login} from 'services/auth'
import {getMinifiedProfile, logIn} from 'services/userService'

export const genericResponseHandler = (
  response,
  type,
  t,
  redirectOptions,
  updateUser = undefined,
  showBanModal = () => {}
) => {
  if (type === 'FACEBOOK' && !response.accessToken) {
    notificate(NOTIFICATIONS_TYPE.ERROR, t('ERRORS.FACEBOOK_AUTHENTICATION_ERROR'))
    return
  }
  if (type === 'GOOGLE' && !((response.Zi && response.Zi.id_token) || response.tokenId)) {
    if ('error' in response && response.error === 'idpiframe_initialization_failed') {
      console.error(response.error)
    } else {
      // This console log is mandatory here to prevent Showcase Google login error.
      // Not sure why, but it helps. Racing condition maybe.
      console.error(response.error)
      notificate(NOTIFICATIONS_TYPE.ERROR, t('ERRORS.ERROR_OCCURED'))
    }
    return
  }

  const logInFormData = {
    ...(type === 'GOOGLE'
      ? {googleToken: response.tokenId || response.Zi.id_token}
      : {facebook_token: response.accessToken}),
    source: 'webapp'
  }

  logIn(
    type,
    logInFormData,
    async logInData => {
      const {token} = logInData.data
      try {
        const data = await getMinifiedProfile(token)
        if (data.isBanned) {
          notificate(NOTIFICATIONS_TYPE.ERROR, t('USER_BANNED_LOGIN_MESSAGE'))
        } else {
          if (typeof updateUser === 'function') {
            updateUser({
              ...data,
              isLogged: true
            })
          }
          login({token, redirectOptions})
          notificate(NOTIFICATIONS_TYPE.SUCCESS, t('SIGN_IN_SUCCESS'))
        }
      } catch (err) {
        console.error('genericResponseHandler', err)
        notificate(NOTIFICATIONS_TYPE.ERROR, t('LOGIN_ERROR'))
      }
    },
    err => {
      if (err === 'USER_BANNED') {
        showBanModal(true)
      } else {
        console.error('genericResponseHandler', err)
        notificate(NOTIFICATIONS_TYPE.ERROR, t('LOGIN_ERROR'))
      }
    }
  )
}
